import React from "react"
import Layout from '../components/layout'
import HTML from '../components/HTML'
import SEO  from '../components/SEO'


function RawHTML(props) {
    const { pageContext } = props
    const { pageContent } = pageContext
    
    return (
        <Layout>
            <HTML key={"HTML"} data={pageContent.HTML} />
            <SEO key={"SEO"} title={"HTML"} data={pageContent.SEO} />
        </Layout>
    )
}

export default RawHTML
